import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../components/Layout"
import * as styles from '../styles/home.module.css'

export default function Home() {
    return (
        <Layout>
            <section className={styles.header}>
                <div>
                    <h2>Design</h2>
                    <h3>Develop & Deploy</h3>
                    <p>Webmaster</p>
                    <Link className={styles.btn} to="/projects">My Projects</Link>
                </div>
                <StaticImage src="../images/banner.png" alt="banner" placeholder="blurred" />
            </section>
        </Layout>
    )
}

